import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import {
  dominantDareImages,
  dominantTruthImages,
  submissiveDareImages,
  submissiveTruthImages
} from './cardImages';  // Ensure this path is correct
import CustomModal from './CustomModal';  // Import the custom modal
import HowToPlayModal from './howtoplaymodal';
import DateOfBirthOverlay from './DateOfBirthOverlay'; // Import DateOfBirthOverlay
import gsap from 'gsap'; // Import GSAP for animations
import emptyCardImage from './assets/Empty_Card.png'; // Import the empty card image
import shuffleSound from './assets/Card_shuffle.mp3';
import cardDealSound from './assets/single_card_deal.mp3';

// Helper function to get image paths
const getImagePath = (folder, imageName) => `${process.env.PUBLIC_URL}/Deck/Cards/${folder}/${imageName}`;

// App component
const App = () => {

  const [deck, setDeck] = useState([]);
  const [drawnCard, setDrawnCard] = useState(null);
  const [discardPile, setDiscardPile] = useState([]);
  const [heldCard, setHeldCard] = useState(null); // State for holding a card
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [isHowToPlay, setHowToPlay] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true); // State for overlay visibility

  // Refs for the piles and deck
  const discardPileRef = useRef(null);
  const heldCardRef = useRef(null);
  const drawnCardRef = useRef(null);
  const deckRef = useRef(null);

  // Store initial positions of cards
  const initialPositions = useRef({
    discardPile: null,
    heldCard: null,
    drawnCard: null,
  });

  useEffect(() => {
    // Combine all images into a single deck
    const allCards = [
      ...dominantDareImages.map(image => getImagePath('DominantDare', image)),
      ...dominantTruthImages.map(image => getImagePath('DominantTruth', image)),
      ...submissiveDareImages.map(image => getImagePath('SubmissiveDare', image)),
      ...submissiveTruthImages.map(image => getImagePath('SubmissiveTruth', image)),
    ];

    // Shuffle deck
    setDeck(shuffle(allCards));
  }, []);

  // Shuffle function
  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Draw a card from the deck
  const drawCard = () => {
    if (deck.length > 0) {
      // Move the current drawn card to discard pile if it exists
      if (drawnCard) {
        const audio = new Audio(cardDealSound);
        audio.play();
        setDiscardPile([drawnCard, ...discardPile]);
      }

      // Draw a new card
      const [card, ...rest] = deck;
      const audio = new Audio(cardDealSound);
      audio.play();
      setDrawnCard(card);
      setDeck(rest);
    } else {
      console.log('No cards left in the deck');
    }
  };

  // Hold the drawn card
  const holdCard = () => {
    if (drawnCard) {
      const audio = new Audio(cardDealSound);
      audio.play();
      setHeldCard(drawnCard); // Set the drawn card as held card
      setDrawnCard(null); // Clear the drawn card
    }
  };

  // Unhold the card
  const unholdCard = () => {
    if (heldCard) {
      const audio = new Audio(cardDealSound);
      audio.play();
      setDiscardPile([heldCard, ...discardPile]); // Move held card to discard pile
      setHeldCard(null); // Clear the held card
    }
  };

  // Discard the drawn card
  const discardCard = () => {
    if (drawnCard) {
      const audio = new Audio(cardDealSound);
      audio.play();
      setDiscardPile([drawnCard, ...discardPile]); // Add the card to the discard pile
      setDrawnCard(null); // Clear the drawn card
    }
  };

  // Animation for moving cards back to the deck
  const animateReset = () => {
    const timeline = gsap.timeline();

    // Store initial positions
    initialPositions.current.discardPile = discardPileRef.current.getBoundingClientRect();
    initialPositions.current.heldCard = heldCardRef.current.getBoundingClientRect();
    initialPositions.current.drawnCard = drawnCardRef.current.getBoundingClientRect();

    // Get the position of the deck
    const deckPosition = deckRef.current.getBoundingClientRect();

    // Move cards to the deck
    timeline
      .to(discardPileRef.current, {
        x: deckPosition.left - initialPositions.current.discardPile.left,
        y: deckPosition.top - initialPositions.current.discardPile.top,
        duration: 0.5,
        ease: 'power2.inOut',
        onComplete: () => discardPileRef.current.style.opacity = 0, // Hide during movement
      })
      .to(heldCardRef.current, {
        x: deckPosition.left - initialPositions.current.heldCard.left,
        y: deckPosition.top - initialPositions.current.heldCard.top,
        duration: 0.5,
        ease: 'power2.inOut',
        onComplete: () => heldCardRef.current.style.opacity = 0, // Hide during movement
      }, '-=0.25')
      .to(drawnCardRef.current, {
        x: deckPosition.left - initialPositions.current.drawnCard.left,
        y: deckPosition.top - initialPositions.current.drawnCard.top,
        duration: 0.5,
        ease: 'power2.inOut',
        onComplete: () => drawnCardRef.current.style.opacity = 0, // Hide during movement
      }, '-=0.25')
      .add(() => {
        // Reset game state after animation
        const allCards = [
          ...deck,
          ...discardPile,
          ...(heldCard ? [heldCard] : []),
        ];
        setDeck(shuffle(allCards));
        setDiscardPile([]); // Clear discard pile
        setHeldCard(null);  // Clear held card
        setDrawnCard(null); // Clear drawn card
        setIsModalOpen(false); // Close modal
      })
      .to(discardPileRef.current, {
        x: 0,
        y: 0,
        opacity: 1,
        duration: 0.5,
        ease: 'power2.inOut',
      })
      .to(heldCardRef.current, {
        x: 0,
        y: 0,
        opacity: 1,
        duration: 0.5,
        ease: 'power2.inOut',
      }, '-=0.25')
      .to(drawnCardRef.current, {
        x: 0,
        y: 0,
        opacity: 1,
        duration: 0.5,
        ease: 'power2.inOut',
      }, '-=0.25');
  };

  // Reset and shuffle all cards
  const resetAndShuffle = () => {
    // Play shuffle sound
    const audio = new Audio(shuffleSound);
    audio.play();

    animateReset();  // Run the reset animation
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeHowToPlay = () => {
    setHowToPlay(false);
  };

  // Confirm and open the modal
  const confirmResetAndShuffle = () => {
    setIsModalOpen(true);
  };

  const HowToPlay = () => {
    setHowToPlay(true);
  };

  // Handle verification in overlay
  const handleVerify = () => {
    setIsOverlayVisible(false);
  };

  return (
    <div className="App">
      {isOverlayVisible && <DateOfBirthOverlay onVerify={handleVerify} />}
      <h1 className="cardgamename">BDSM Card Game</h1>
      <div className="container">
        <div className="deck-container" ref={deckRef}>
          <h2 className="fancyfont">Deck</h2>
          <img 
            src={deck.length > 0 ? `${process.env.PUBLIC_URL}/Deck/Placeholders/Cardback.jpeg` : emptyCardImage} 
            alt="Deck Back" 
            className="deck-back" 
          />
        </div>
        <div className="drawn-card-container" ref={drawnCardRef}>
          <h2 className="fancyfont">Card Drawn</h2>
          {drawnCard ? (
            <img src={drawnCard} alt="Drawn Card" className="drawn-card" />
          ) : (
            <img 
              src={`${process.env.PUBLIC_URL}/Deck/Placeholders/Current_Card_Placeholder.png`} 
              alt="Current Card Placeholder" 
              className="drawn-card" 
            />
          )}
        </div>
        <div className="hold-card-container" ref={heldCardRef}>
          <h2 className="fancyfont">Hold Card</h2>
          {heldCard ? (
            <img src={heldCard} alt="Held Card" className="held-card" />
          ) : (
            <img 
              src={`${process.env.PUBLIC_URL}/Deck/Placeholders/Holding_Card_Placeholder.png`} 
              alt="Holding Card Placeholder" 
              className="held-card" 
            />
          )}
        </div>
        <div className="discard-pile-container" ref={discardPileRef}>
          <h2 className="fancyfont">Discard Pile</h2>
          {discardPile.length > 0 ? (
            <img 
              src={discardPile[0]} 
              alt="Discard Pile" 
              className="discard-pile" 
            />
          ) : (
            <img 
              src={`${process.env.PUBLIC_URL}/Deck/Placeholders/Discard_Pile.png`} 
              alt="Discard Pile" 
              className="discard-pile" 
            />
          )}
        </div>
      </div>
      <div className="controls">
        <button onClick={drawCard}>Draw Card</button>
        <button onClick={holdCard}>Hold Card</button>
        <button onClick={unholdCard}>Unhold Card</button>
        <button onClick={discardCard}>Discard Card</button>
        <button onClick={confirmResetAndShuffle}>Reset/Shuffle</button>
        <button onClick={HowToPlay}>How To Play</button>
      </div>
      {/* Custom Modal */}
      <CustomModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={resetAndShuffle}
      />

      <HowToPlayModal 
        isOpen={isHowToPlay}
        onConfirm={closeHowToPlay}
      />
    </div>
  );
};

export default App;
