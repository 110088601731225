import React from 'react';
import './howtoplaymodal.css'; // Add your styles in this file

const HowToPlayModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal-content">
        <h1>How To Play</h1>
        <ol class="instructions">
          <li>This game is designed to help bring a level of kinkiness into relationships.</li>
          <li>This game is good for a minimum of 2 to 4 players.</li>
          <li>The game begins by the first person drawing a card.</li>
          <li>There are 2 types of cards, and 2 sub classes.</li>  
            <ul>
              <li><b>Type 1: Dominant cards</b></li>
              <li><b>Type 2: Submissive cards</b></li>
              <li><b>Sub-type 1: Truth cards</b></li>
              <li><b>Sub-type 2: Dare cards.</b></li>
            </ul>
          <li>If a <b>Dominant Card</b> is drawn by a player, that player will act as the <b>Dominant</b>. They will then follow through with the task.</li>
            <ul>
              <li>For <b>Truth Cards</b>, the <b>Dominant</b> will answer the question truthfully. If they refuse, they will follow through with the punishment on the card.</li>
              <li>For <b>Dare Cards</b>, the <b>Dominant</b> will do the actions on the card. If they refuse, they will follow through with the punishment on the card.</li>
                <ul>
                  <li>If the action involves the <b>Dominant</b> commanding a <b>Submissive</b> to do something, the <b>Dominant</b> can choose anyone to be their submissive.</li>
                </ul>
            </ul>
          <li>If a <b>Submissive Card</b> is drawn by a player, that player will act as the <b>Submissive</b>. They will then follow through with the task.</li>
            <ul>
              <li>For <b>Truth Cards</b>, the <b>Submissive</b> will answer the question truthfully. If they refuse, they will follow through with the punishment on the card.</li>
              <li>For <b>Dare Cards</b>, the <b>Submissive</b> will do the actions on the card. If they refuse, they will follow through with the punishment on the card.</li>
                <ul>
                  <li>If the action involves the <b>Submissive</b> commanding a <b>Dominant</b> to do something, the <b>Submissive</b> can choose anyone to be their <b>Dominant</b>.</li>
                </ul>
            </ul>
          <li>Some cards require they be "Held." For instance, if a card says for the <b>Dominant</b> to lose/skip a turn, then that card can be held until the <b>Dominant's</b> next turn where they will lose a turn. This can also be used to hold cards with timed tasks, such as a <b>Submissive</b> asking for permission to talk for the next 10 minutes.</li>
        </ol>
        <div className="custom-modal-buttons">
          <button className="confirm-button" onClick={onConfirm}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default HowToPlayModal;
