import React, { useState } from 'react';
import './DateOfBirthOverlay.css'; // Import the CSS file

const DateOfBirthOverlay = ({ onVerify }) => {
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [error, setError] = useState('');

  const handleVerify = () => {
    // Check if all fields are filled
    if (!month || !day || !year) {
      setError('Please fill in all fields.');
      return;
    }

    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();

    // Adjust age if the birthday hasn't occurred yet this year
    if (today.getMonth() + 1 < month || (today.getMonth() + 1 === month && today.getDate() < day)) {
      age--;
    }

    if (age >= 18) {
      onVerify(); // Call onVerify if the user is 18 or older
    } else {
      setError('You must be at least 18 years old to access this website.');
    }
  };

  return (
    <div className="overlay">
      <div className="overlay-content">
        <h2>Date of Birth Verification</h2>
        <p>
          To access this website, you need to be at least 18 years old. Please enter your date of birth below.
        </p>
        <select name="month" id="month" value={month} onChange={(e) => setMonth(e.target.value)} required>
          <option value="">Month</option>
          {Array.from({ length: 12 }, (_, i) => (
            <option key={i} value={i + 1}>{new Date(0, i).toLocaleString('en', { month: 'long' })}</option>
          ))}
        </select>
        <select name="day" id="day" value={day} onChange={(e) => setDay(e.target.value)} required>
          <option value="">Day</option>
          {Array.from({ length: 31 }, (_, i) => (
            <option key={i} value={i + 1}>{i + 1}</option>
          ))}
        </select>
        <select name="year" id="year" value={year} onChange={(e) => setYear(e.target.value)} required>
          <option value="">Year</option>
          {Array.from({ length: 57 }, (_, i) => (
            <option key={i} value={2023 - i}>{2023 - i}</option>
          ))}
        </select>
        {error && <p className="error-message">{error}</p>}
        <p>
          By clicking this button you agree that the date of birth entered is accurate and within the age of consent in your region.
        </p>
        <button onClick={handleVerify}>Verify</button>
      </div>
    </div>
  );
};

export default DateOfBirthOverlay;
