import React from 'react';
import './CustomModal.css'; // Add your styles in this file

const CustomModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="custom-modal-overlay">
      <div className="custom-modal-content">
        <h2>Confirmation</h2>
        <p>Pressing this button will reset all cards into the deck, and shuffle them. Do you wish to continue?</p>
        <div className="custom-modal-buttons">
          <button className="confirm-button" onClick={onConfirm}>Yes</button>
          <button className="cancel-button" onClick={onClose}>No</button>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
