export const dominantDareImages = [
  'DominantDare_Eight.png', 'DominantDare_Eighteen.png', 'DominantDare_Eleven.png',
  'DominantDare_Fifteen.png', 'DominantDare_Five.png', 'DominantDare_Four.png',
  'DominantDare_Fourteen.png', 'DominantDare_Nine.png', 'DominantDare_Nineteen.png',
  'DominantDare_One.png', 'DominantDare_Seven.png', 'DominantDare_Seventeen.png',
  'DominantDare_Six.png', 'DominantDare_Sixteen.png', 'DominantDare_Ten.png',
  'DominantDare_Thirteen.png', 'DominantDare_Three.png', 'DominantDare_Twelve.png',
  'DominantDare_Twenty.png', 'DominantDare_TwentyFive.png', 'DominantDare_TwentyFour.png',
  'DominantDare_TwentyOne.png', 'DominantDare_TwentyThree.png', 'DominantDare_TwentyTwo.png',
  'DominantDare_Two.png'
];

export const dominantTruthImages = [
  'DominantTruth_Eight.png', 'DominantTruth_Eighteen.png', 'DominantTruth_Eleven.png',
  'DominantTruth_Fifteen.png', 'DominantTruth_Five.png', 'DominantTruth_Four.png',
  'DominantTruth_Fourteen.png', 'DominantTruth_Nine.png', 'DominantTruth_Nineteen.png',
  'DominantTruth_One.png', 'DominantTruth_Seven.png', 'DominantTruth_Seventeen.png',
  'DominantTruth_Six.png', 'DominantTruth_Sixteen.png', 'DominantTruth_Ten.png',
  'DominantTruth_Thirteen.png', 'DominantTruth_Three.png', 'DominantTruth_Twelve.png',
  'DominantTruth_Twenty.png', 'DominantTruth_TwentyFive.png', 'DominantTruth_TwentyFour.png',
  'DominantTruth_TwentyOne.png', 'DominantTruth_TwentyThree.png', 'DominantTruth_TwentyTwo.png',
  'DominantTruth_Two.png'

];

export const submissiveDareImages = [
  'SubmissiveDare_Fifteen.png', 'SubmissiveDare_Five.png', 'SubmissiveDare_Four.png',
  'SubmissiveDare_Eight.png', 'SubmissiveDare_Eighteen.png', 'SubmissiveDare_Eleven.png',
  'SubmissiveDare_Fourteen.png', 'SubmissiveDare_Nine.png', 'SubmissiveDare_Nineteen.png',
  'SubmissiveDare_One.png', 'SubmissiveDare_Seven.png', 'SubmissiveDare_Seventeen.png',
  'SubmissiveDare_Six.png', 'SubmissiveDare_Sixteen.png', 'SubmissiveDare_Ten.png',
  'SubmissiveDare_Thirteen.png', 'SubmissiveDare_Three.png', 'SubmissiveDare_Twelve.png',
  'SubmissiveDare_Twenty.png', 'SubmissiveDare_TwentyFive.png', 'SubmissiveDare_TwentyFour.png',
  'SubmissiveDare_TwentyOne.png', 'SubmissiveDare_TwentyThree.png', 'SubmissiveDare_TwentyTwo.png',
  'SubmissiveDare_Two.png'
];

export const submissiveTruthImages = [
  'SubmissiveTruth_Fifteen.png', 'SubmissiveTruth_Five.png', 'SubmissiveTruth_Four.png',
  'SubmissiveTruth_Eight.png', 'SubmissiveTruth_Eighteen.png', 'SubmissiveTruth_Eleven.png',
  'SubmissiveTruth_Fourteen.png', 'SubmissiveTruth_Nine.png', 'SubmissiveTruth_Nineteen.png',
  'SubmissiveTruth_One.png', 'SubmissiveTruth_Seven.png', 'SubmissiveTruth_Seventeen.png',
  'SubmissiveTruth_Six.png', 'SubmissiveTruth_Sixteen.png', 'SubmissiveTruth_Ten.png',
  'SubmissiveTruth_Thirteen.png', 'SubmissiveTruth_Three.png', 'SubmissiveTruth_Twelve.png',
  'SubmissiveTruth_Twenty.png', 'SubmissiveTruth_TwentyFive.png', 'SubmissiveTruth_TwentyFour.png',
  'SubmissiveTruth_TwentyOne.png', 'SubmissiveTruth_TwentyThree.png', 'SubmissiveTruth_TwentyTwo.png',
  'SubmissiveTruth_Two.png'    
];


  